.principal-nav {
    width: 63%;
    float: right;
    margin-top: rem(60);
    transition: all .3s linear 0s;
    @include bp-lt($tabletAL) {
        width: 70%;
    }
    @include bp-lt($medium) {
        width: 80%;
        margin-top: rem(35);
        padding-left: rem(20);
        text-align: right;
    }
    @include bp-lt($tabletA) {
        width: 100%;
        text-align: center;
        margin-top: rem(20);
    }
    @include bp-lt($mobileDefault) {
        display: none;
    }
    >ul.menu {
        text-align: right;
        @include bp-lt($tabletAL) {
            text-align: center;
        }
        @include bp-lt($small) {
            text-align: left;
        }
    }
    ul>li {
        display: inline-block;
        position: relative;
        margin-right: 2.5vw;

        @include bp-lt($medium) {
            margin-right: 1vw;
        }
        @include bp-lt($mobileDefault) {
            display: block;
            text-align: left;
            margin-right: 0;
            padding: rem(14) 0;
            border-bottom: $border;
        }
        a,
        span {
            color: $primary-color;
            font-family: $font2;
            font-size: rem(15);
            text-transform: uppercase;
            font-weight: bold;
            padding-bottom: rem(25);
            cursor: pointer;

            @include bp-lt($medium) {
                font-size: rem(14);
            }

            &:after {
                content: '';
                width: 100%;
                height: 2px;
                display: block;
                margin-top: rem(4);
                background-color: $pistache2;
                transition: 0.2s transform;
                transform: scale(0);
            }
            @include bp-lt($mobileDefault) {
                padding-left: rem(10);
                display: block;
                padding-bottom: 0;
            }
        }
        &:last-child {
            margin-right: 0;
            border-bottom: none;
        }
        &:hover {
            @include bp-gt($mobileDefault) {
                ul {
                    display: block;
                }
            }
            @include bp-lt($mobileDefault) {
                background-color: $pistache2;
                a:after,
                span:after {
                    display: none;
                }
            }
            a:after,
            span:after {
                transform: scale(1);
            }
        }
        &>ul {
            background-color: $pistache2;
            position: absolute;
            left: rem(-70);
            top: rem(42);
            z-index: 2;
            width: rem(315);
            padding: rem(30) 0 rem(20) rem(21);
            box-shadow: 7px 6px 20px rgba(0, 0, 0, 0.1) inset;
            display: none;
            @include bp-lt($mobileDefault) {
                position: static;
                width: 100%;
                margin-left: 0;
                padding: 0;
                box-shadow: none;
            }
            &>li {
                margin-bottom: 1rem;
                text-transform: none;
                display: block;
                text-align: left;
                border-bottom: none;
                margin-right: 0;
                @include bp-lt($mobileDefault) {
                    margin: 0;
                    padding-left: 1rem;
                }
                a {
                    color: $white;
                    font-weight: normal;
                    padding-bottom: 0;
                    text-transform: inherit;
                    &:before {
                        content: '>';
                        margin-right: rem(10);
                        color: $olive;
                        font-size: rem(11);
                    }
                    &:after {
                        display: none;
                    }
                    &:hover {
                        font-weight: bold;
                        &:before {
                            color: $white;
                        }
                    }
                }
            }
        }

        @include bp-lt($large) {
            &:last-of-type {
                &>ul {
                    left: auto;
                    right: 0;
                }
            }
        }

        @include bp-lt($desktop) {
            &:nth-last-child(2) {
                &>ul {
                    left: auto;
                    right: 0;
                }
            }
        }
    }
}
