// ========================================= //
// typos
// ========================================= //
@import url('https://fonts.googleapis.com/css?family=Merriweather+Sans:300,400,700|Muli:300,400,300i,400i|Titillium+Web:300,400,600,700');
@font-face {
	font-family: 'flexslider-icon';
	src: url('#{$fonts}flexslider-icon.eot');
	src: url('#{$fonts}flexslider-icon.eot?#iefix') format('embedded-opentype'),
	url('#{$fonts}flexslider-icon.woff') format('woff'),
	url('#{$fonts}flexslider-icon.ttf') format('truetype'),
	url('#{$fonts}flexslider-icon.svg#flexslider-icon') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'icomoon';
	src : url('#{$fonts}icomoon.eot?ftbugk');
	src : url('#{$fonts}icomoon.eot?ftbugk#iefix') format('embedded-opentype'),
	url('#{$fonts}icomoon.ttf?ftbugk') format('truetype'),
	url('#{$fonts}icomoon.woff?ftbugk') format('woff'),
	url('#{$fonts}icomoon.svg?ftbugk#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
}

body {
	font-family: $font1;
	color: $maroon2;
}

p {
	font-size: rem(15);
	font-family: $font1;
	line-height: 2;
	color: $maroon2;
}

.page-news-detail,
.node-page {
	max-width: rem(1070);
	margin: 0 auto;
	padding-left: rem(5);
	padding-right: rem(5);
	ul {
		li {
			margin-bottom: rem(12);
			color: $maroon2;
			margin-left: rem(14);
			&:before {
				content: '';
				display: inline-block;
				width: 5px;
				height: 5px;
				border-radius: 50%;
				background-color: $pistache;
				margin-right: 1rem;
				position: relative;
				bottom: 3px;
			}
		}
	}
}

.page-title {
	background-color: $silver;
	border-bottom: 1px solid $silver2;
	padding: rem(40) rem(10) rem(35);
	@include bp-lt($small) {
		padding: rem(20) rem(10);
	}
	text-align: center;
}

h1,
h2,
h3,
h4 {
	color: $maroon2;
	font-family: $font2;
}

h2,
h3,
h4 {
	margin-bottom: rem(20);
}

h3,
h4 {
	letter-spacing: 2px;
	text-transform: uppercase;
}

h1,
h2,
.title {
	font: {
		size: rem(52);
		weight: bold;
	}
	span {
		font-size: rem(20);
		margin-top: rem(20);
		text-transform: uppercase;
		font-weight: normal;
		display: block;
		padding-left: rem(113);
		letter-spacing: 2px;
	}
	@include bp-lt($small) {
		font-size: rem(36);
		span {
			padding-left: rem(20);
		}
	}
}

h2 {
	font-weight: 600;
	font-size: rem(26);
}

h3 {
	font-weight: bold;
	font-size: 1rem;
}

h4 {
	color: $pistache2;
	font-size: rem(14);
}

.row.expanded {
	max-width: 100%;
}

.title-left,
.title-center {
	font-family: $font2;
	font-weight: bold;
	font-size: rem(52);
	color: $maroon2;
	span {
		font-family: $font4;
		font-size: rem(20);
		text-transform: uppercase;
		display: block;
		letter-spacing: 3px;
		font-weight: 300;
		margin-top: rem(10);
		padding-left: rem(155);
	}
	@include bp-lt($small) {
		text-align: left;
		font-size: rem(36);
		span {
			margin-top: rem(4);
			letter-spacing: 1px;
			padding-left: rem(30);
		}
	}
}

.title-center {
	font-size: rem(46);
	text-align: center;
	span {
		padding-left: 0;
	}
}

strong,
b {
	font-weight: bold;
}

em,
i {
	font-style: italic;
}

.row-table {
	overflow-y: auto;
}

hr {
	margin: rem(35) 0;
}

table {
	width: 100%;
	border: $border;
	border-color: $silver5;
	margin-top: rem(35);
	margin-bottom: rem(35);
	thead,
	tbody {
		tr {
			th,
			td {
				padding: rem(18) rem(22) rem(14);
				border: 1px solid $silver6;
				background-color: $silver;
			}
		}
	}
	thead tr td,
	tbody tr th {
		background-color: $silver7;
	}
	thead td:first-child {
		background-color: $silver8;
	}
	tr {
		width: 25%;
	}
	tbody th,
	thead td {
		color: $maroon2;
		font-size: rem(15);
		font-family: $font2;
		text-transform: uppercase;
		letter-spacing: 2px;
		font-weight: bold;
	}
	@include bp-lt($small) {
		margin-top: rem(20);
	}
}

.wrapper {
	padding-top: rem(50);
	padding-bottom: rem(45);
	@include bp-lt($small) {
		padding: rem(20) 0;
	}
}
