.pagecms {
	// padding-top: rem(50);
	// padding-bottom: rem(45);
	padding: rem(50) rem(15) rem(45);
    max-width: rem(1100);
    margin: 0 auto;
    overflow: hidden;
}
.pagecms, .block-metier .desc {
	img {
		height: auto !important;
	}
	.list {
		margin-top: rem(35);
		margin-bottom: rem(10);
	}
	a{
		color: $pistache;
		text-decoration: underline;
		&:hover{
			color: $maroon;
		}
		
	}
	p {
		margin-bottom: rem(18);
	}
	p iframe:parent{
		width: 100%;
		margin: 0;
		iframe{
			width: 100%;
		}
	}
	ul {
		li {
			margin-bottom: rem(12);
			color: $maroon2;
			margin-left: rem(14);
			&:before {
				content: '';
				display: inline-block;
				width: 5px;
				height: 5px;
				border-radius: 50%;
				background-color: $pistache;
				margin-right: 1rem;
				position: relative;
				bottom: 3px;
			}
		}
	}

	.float-left { float: left; }

    .float-right { float: right; }
	blockquote {
		font-family: $font1;
		font-size: rem(15);
		font-style: italic;
		line-height: 1.75;
		text-align: center;
		color: $primary-color;
		margin: rem(80) rem(50) rem(50);
		padding: 0 rem(80);
		position: relative;
		@include bp-lt($small) {
			padding: 0 rem(10);
			margin: rem(80) rem(10) rem(50);
		}
		p {
			font-style: italic !important;
		}

		&:before, &:after {
			position: absolute;
			color: $pistache4;
			@extend %iconfont;
			font-size: rem(50);
		}

		&:before {
			content: "\e977";
			top: 0;
			left: 0;
			transform: translateY(-50%);
		}

		&:after {
			content: "\e978";
			bottom: 0;
			right: 0;
			transform: translateY(50%);
		}
	}

	@include bp-lt($mobileDefault) {
		blockquote {
			&:before { transform: translateY(-100%); }
			&:after { transform: translateY(100%); }
		}
	}
}