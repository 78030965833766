// ========================================= //
// transition
// ========================================= //

@mixin transition-mixin($value,$val) {
  @if $value == transform{
    -webkit-transition:-webkit-transform $val;  
    -ms-transition: transform $val;
    transition: transform $val;
  }@else {
     -webkit-transition:$value $val;
    -moz-transition: $value $val;
    transition: $value $val;
  }
}