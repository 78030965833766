// ========================================= //
// fontFamily
// ========================================= //

$iconFont       : "iconfont";


$font1 : 'Muli', sans-serif;
$font2 : 'Titillium Web', sans-serif;
$font3 : 'Merriweather Sans', sans-serif;
$font4 : "Myriad Pro", Myriad,"Helvetica Neue", Helvetica, Arial, sans-serif;