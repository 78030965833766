// ========================================= //
// slider
// ========================================= //


.hp-slider {
	background-color: $maroon;
	.row {
		max-width: 100%;
	}

	// $right : 530*100%/1600;


	&.flexslider {
		.flex-direction-nav {
			a {
				opacity: 1;
				top: 100%;
				background-color: $white;
				transform: translateY(-50%);
				width: 54px;
				height: 54px;
				text-shadow: none;
				font-size: 0;
				text-align: center;
				margin-top: -27px;
				&:before {
					font-size: 18px;
					color: $maroon2;
					padding-top: 20px;
				}
			}

			.flex-next, .flex-prev { bottom: 0; }
			.flex-next {
				left: auto;
				border-left: 1px solid $silver10;
			}
			.flex-prev {
				right: auto;
				left: auto;
				border-right: 1px solid $silver9;
			}

		}
	}

	.slider-visuel {
		display: inline-block;
		// float: left;
		// width:  1070*100%/1600;
	}

	.slider-desc {
		background-color: $maroon;
		color: $white;
		text-align: center;
		// float: left;
		// width: 530*100%/1600;
		display: table;
		max-width: rem(440);
		width: 100%;
		margin: auto;
		&-item {
			display: table-cell;
			vertical-align: middle;
			padding: 0 rem(10);

		}

		h2 {
			color: $white;
			font-size: rem(38);
			&:after {
				content: '';
				display: block;
				width: rem(32);
				height: 2px;
				background-color: $pistache;
				margin: rem(45) auto rem(35);
			}
		}
		p {
			margin-bottom: rem(25);
			line-height: 1.5;
			color: $white;
		}
		a {
			color: $white;
			text-decoration: underline;
		}
	}

	@include bp-gt($medium) {
		.slider-visuel { width:  1070*100%/1600;float: left; }
		.slider-desc { width: 530*100%/1600; }
	}

	@include bp-lt($medium) {
		.slider-desc .slider-desc-item{ 
			padding: rem(20) rem(10); 
			height: auto !important; 

		}
	}

	@include bp-lt($small) {
		.slider-desc {
			h2 {
				font-size: rem(32);
				&:after {
					margin: rem(25) auto rem(15);
				}
			}
			p {
				margin-bottom: rem(18);
			}
		}
	}

}

.imageloaderDefault{
	background: url('#{$img}rolling.gif') no-repeat center / 6rem auto;
	text-align: center;
	img {
		opacity: 0;
	}
}


// page home block solution

.solution {
	.slider {
		@include bp-gt($xsmall) {
			margin-left: rem(120);
			margin-top: rem(-110);
    	}
    	@include bp-lt($xsmall) {
    		.button {
				max-width: rem(295);
				display: block;
				margin: 0 auto;
			}
    	}

    	.flex-direction-nav {
    		.flex-disabled {
    			opacity: 1 !important;
    			z-index: 1;
    			text-decoration: none;
    		}
			a {
				opacity: 1;
				font-size: 0;
				right: auto;
				top: 15px;
				left: 19px;
				width: 18px;
				height: 18px;
				text-decoration: none;
				outline: none;
				@include bp-gt($xsmall) { transform: rotate(90deg); }
				&.flex-next { top: 0; }
				&:before { font-size: 18px; }
				@include bp-lt($xsmall) {
					top: 52px!important;
					&.flex-next { right: -10px; left: auto;}
					&.flex-prev {  left: -10px;}
				}
				@include bp-bt($xsmall, $medium) { top: 280px - 25px; }
			}
		}
	}
	.block-left {
		margin-top: rem(20);
		@include bp-gt($mobileLO) {
			padding-right: rem(35);
			margin-top: rem(120);
		}
	}

	.block-slider {
		position: relative;
		.slider, .carousel{
			ul.slides{
				li{ display: none; }
			}
		}
	}
	.carousel {
		position: relative;

		@include bp-gt($xsmall) {
			top: 40px;
			left: 65px;
		  	max-width: 350px;
		  	transform: rotate(90deg);
		  	transform-origin: left top 0;
		}
		@include bp-bt($xsmall, $medium) { top: 280px; }
		&:after {
			content: '';
			width: 105%;
			height: 1px;
			background-color: $silver2;
			display: block;
			position: absolute;
			top: 50%;
			z-index: -1;
			left: -5px;


			@include bp-gt($xsmall) {
				left: -40px;
				width: 121%;
			}
			@include bp-lt($xsmall) { transform: translateY(-50%); }

		}

		.slides {
			li {
				width: 70px!important;
				margin-right: 0 !important;
				@include bp-lt($xsmall) {
					margin-top: 10px;
				}
				.big {
					width: rem(70);
					height: rem(70);
					margin: 0 auto;
					.small {
						text-align: center;
						border: 1px solid $silver2;
						border-radius: 50%;
						position: relative;
						background-color: $white;
						width: rem(50);
						height: rem(50);
						margin: 0 auto;
						transition: 0.3s all;
					}
				}


				@include bp-gt($xsmall) {
					transform: rotate(-90deg);
				}
				img {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					display: inline-block;
					&.show-in-active-slide {
						visibility: hidden;
						opacity: 0;
					}
				}
				&.flex-active-slide {
					margin-right: 20px !important;
					margin-top: 0;
					@include bp-bt($mobileS, $xsmall) {
						margin-right: 0!important;
					}
					@include bp-lt($xsmall) {
						margin-left: 5px;
					}
					.big .small {
						width: 100%;
						height: 100%;
						background-color: $pistache2;
						border: 0 none;
					}
					img {
						user-select : none;
						&.show-in-active-slide { visibility: visible; opacity: 1;}
						&.hide-in-active-slide { visibility: hidden; opacity: 0;}
					}
				}
			}
		}
	}
}

.block-prise-simple .flexslider {
	.flex-direction-nav {
		a {
			opacity: 1;
			top: 100%;
			background-color: $white;
			transform: translateY(-50%);
			width: 54px;
			height: 54px;
			text-shadow: none;
			font-size: 0;
			text-align: center;
			margin-top: -27px;
			&:before {
				font-size: 18px;
				color: $maroon2;
				padding-top: 20px;
			}
		}
		.flex-prev {
			left: 0;
			bottom: 0;
			border-right: 1px solid $silver9;
		}
		.flex-next {
			right: auto;
			left: 53px;
			bottom: 0;
			border-left: 1px solid $silver10;
		}
	}
}
