// ========================================= //
// clear.drupal
// ========================================= //
//Clear module menu
ul li.leaf,
ul li.expanded,
ul li.collapsed {
	list-style-type: none;
	list-style-image: none;
	padding: 0;
	margin: 0;
} //Clear Mothership theme
a:hover,
a:focus {
	text-decoration: none;
}

ul.menu li {
	margin: 0;
	padding: 0;
}

li.expanded,
li.collapsed,
li.leaf {
	padding: 0;
}

ul.menu li {
	margin: 0;
}

.tabs {
	padding: 0;
	margin: 0;
	border: none;
	position: fixed;
	top: 200px;
	right: 0;
	width: 10.67rem;
	z-index: 999;
	background: #c81a24;
	li {
		border-bottom: 1px solid $primary-color;
		text-align: center;
		a {
			padding: 5px 0;
			float: none;
			display: block;
			width: 100%;
			border: none;
			color: $white;
		}
	}
}

#user-login .form-item,
.form-actions {
	margin-bottom: 20px;
}

.form-item,
.form-actions {
	input[type="submit"] {
		-webkit-appearance: none;
		-moz-appearance: none;
		border: none;
		border-radius: 10px;
		border-bottom: 2px solid #21150d;
		cursor: pointer;
		font-family: "Titillium Web", sans-serif;
		letter-spacing: 1px;
		font-weight: bold;
		line-height: 1;
		position: relative;
		text-align: center;
		text-decoration: none;
		text-transform: uppercase;
		display: inline-block;
		padding: 1.5rem 1.875rem 1.25rem 1.875rem;
		font-size: .9375rem;
		background-color: #b3c105;
		border-color: #8f9a04;
		color: #fff;
		transition: background-color 300ms ease-out;
		&:hover {
			background-color: $olive;
		}
		&:before {
			content: '>';
			margin-right: .25rem;
		}
	}
}

#admin-menu input.admin-menu-search {
	height: auto;
}

#geoloc-form input.form-submit {
	display: none;
}

.admin-menu {
	header {
		top: 29px;
	}
}

ul li.leaf,
ul li.expanded {
	list-style: none;
}

#user-login {
	max-width: rem(945);
	width: 100%;
	margin: 0 auto;
	padding: 0 rem(10);
	.form-item {
		margin-bottom: rem(20);
		label {
			span {
				color: $maroon;
			}
		}
	}
}

#sliding-popup {
	padding: rem(10) 0 rem(20);
	height: auto !important;
	background-color: $grey1 !important;
	@include bp-lt($small) {
		padding-bottom: rem(35);
	}
	.popup-content {
		#popup-text {
			margin: 0;
			p {
				font-size: rem(14);
				margin: 0;
			}
		}
		#popup-buttons button {
			margin: 0;
			font-size: 0.8rem;
			@include bp-lt($medium) {
				margin-bottom: rem(10);
			}
		}
	}
}

.page-user #user-login {
	margin-top: rem(50);
	.form-item {
		width: 50%;
		padding-left: rem(10);
		padding-right: rem(10);
		float: left;
		position: relative;
		margin-bottom: rem(27);
	}
	.form-text {
		border-radius: rem(10);
		border: 1px solid $silver12; // @include box-shadow-none();
		margin-bottom: 0;
		height: rem(43);
		padding-left: rem(20);
		padding-right: rem(20);
	}
	label {
		padding-left: rem(10);
		padding-right: rem(10);
		margin-left: rem(10);
		margin-right: rem(10);
		background-color: $white;
		position: absolute;
		top: 0;
		transform: translateY(-50%);
		font-family: $font1;
		font-size: rem(12);
		text-transform: uppercase;
		letter-spacing: 2px;
		z-index: 1;
		span {
			color: $pistache;
			position: relative;
			top: -5px;
			left: -5px;
		} // &.required {
		//     &:after {
		//         content: '*';
		//         color: $pistache;
		//         position: relative;
		//         top: -5px;
		//     }
		// }
	}
	.description {
		margin-top: rem(5);
		font-size: rem(12);
	}
	.form-actions {
		text-align: center;
		clear: both;
	}
	@include bp-lt($small) {
		.form-item {
			width: 100%;
		}
	}
}

.form-item,
.page-user #user-login .form-item {
	input.error,
	textarea.error,
	.selector.error {
		border: 1px solid red;
	}
	label.error {
		font-size: rem(12);
		text-align: left;
		margin-top: rem(5);
		position: relative;
		top: 5px;
		text-transform: none;
		letter-spacing: 0;
	}
	label.error-ch {
		color: $error;
	}
}

.form-item,
.page-user #user-login .form-item {
	label.error {
		font-size: rem(12);
		text-align: left;
		margin-top: rem(5);
		position: relative;
		top: 5px;
		text-transform: none;
		letter-spacing: 0;
	}
}
