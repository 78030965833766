.page404 {
	background: url(#{$img}bg-404.png) no-repeat top left;
	@include bp-lt($mobileXl){
		background: url(#{$img}bg-404-mobile.png) no-repeat;
	}
	.zopim {
		display: none!important;
	} // .left-bloc-404 {
	// 	max-width: 39%;
	// 	width: 100%;
	// 	height: 100vh;
	// 	background-color: $pistache2;
	// }
	.wrapper404 {
		max-width: rem(600);
		position: absolute;
		top: 50%;
		left: 50%;
		padding-left: rem(10);
		padding-right: rem(10);
		h1,
		p {
			font-family: $font2;
			font-weight: 600;
		}
		h1 {
			margin-bottom: rem(10);
			font-size: rem(72);
		}
		p {
			font-size: rem(30);
			margin-bottom: rem(120);
		}
		@include bp-lt($tabletAL) {
			top: 0;
			left: 0;
		}
		@include bp-lt($medium) {
			p {
				margin-bottom: rem(30);
			}
		}
	}
	@include bp-lt($xmedium ) {
		background-position: -210px center;
	}
	@include bp-lt($small) {
		background-position: -360px center;
		.wrapper404 {
			max-width: rem(320);
			top: 0;
			left: 0;
			transform: translate(0, 0);
			width: 100%;
			h1 {
				font-size: rem(32);
			}
			p {
				font-size: rem(18);
				line-height: 1.3;
				margin-bottom: rem(10);
			}
			.button {
				padding: rem(20) rem(15);
				font-size: rem(12);
			}
		}
	}
	
	@include bp-lt($xsmall) {
		background-position: -450px center;
		.wrapper404 {
			top: rem(450);
			width: 100%;
			text-align: center;
			max-width: 100%;
			p {
				font-size: rem(15);
			}
			.button {
				max-width: rem(230);
				display: block;
				margin: 0 auto;
				text-align: center;
			}
		}
	}

	@include bp-lt($mobileM) {
		.wrapper404 {
			top: rem(410);
		}
	}
	@include bp-lt($mobileS) {
		.wrapper404 {
			top: rem(350);
		}
	}
}
