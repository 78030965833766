// ========================================= //
// buttons
// ========================================= //


.button {
	font-family: $font2;
	font-weight: bold;
	letter-spacing: 1px;
	&:before {
		content: '>';
		margin-right: rem(4);
	}
}

.button.secondary {
	padding: 20px 35px;
	width: 100%;
	margin-top: 0;
	box-shadow: -160px 0 150px rgba(255,255,255,0.2) inset;
	position: relative;
	background-color: $maroon;
	border-bottom: none;
	border: $border;
	border-radius: 0;
	position: relative;
	&:before {
		display: none;
		// height: 100%;
		// top: 0;
		// left: 0;
		// background-color: rgba(255,255,255,0.2);
		// position: absolute;
		// transition: 0.5s width;
	}
	// &:hover:before {
	// 	width: 100%;
	// }
}

#cboxWrapper {
	#cboxClose {
		font-size: 0;
		position: absolute;
		top: 0;
		right: 0;
		background-color: rgba(0,0,0,0.3);
		padding: 5px;
		border-radius: 0;
		border: none;

		&:before {
			content: 'x';
			font-size: rem(16);
		}
	}
}


