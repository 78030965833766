.page-node-499 {
	.row .row {
		margin: 0;
	}

	main .row {
		max-width: 100%;
		.columns { padding: 0; }
	}
	.title-left {
		margin-bottom: rem(50);
		position: relative;
		left: -24px;
		padding-top: rem(50);
		span {
			padding-left: rem(105);
			font-family: $font1;
			font-weight: 300;
		}
	}
	.contacter {
		max-width: rem(840 + 50);
        padding: 0 rem(25);
		margin: 0 auto;
		position: relative;

        @include bp-lt($medium) {
            max-width: 100%;
        }

        @include bp-lt($small) {
            padding: 0 rem(10);
        }

		&:before {
			content: '';
		    background-color: $silver2;
		    width: 1px;
		    height: 100%;
		    position: absolute;
		    left: rem(25);
		}
		.form-wrapper {
			max-width: rem(715);
			margin: 0 auto;
			position: relative;

            @include bp-lt($desktop) {
                max-width: 100%;
                width: 100%;
                padding: 0 rem(20);
            }

            @include bp-lt($small) {
                padding: 0;
            }
		}
	}
	.joindre {
		background-color: $silver;
		.title-left {
			span {
				padding-left: rem(70);
			}
		}
	}

	.form-actions {
		clear: both;
		text-align: right;
		padding-left: rem(10);
		padding-right: rem(10);
	}

	button {
		border: none;
		border-bottom: 2px solid $olive2;
		margin: rem(10);
		position: relative;
	}

	.icon {
		display: inline-block;
		width: rem(50);
		height: rem(50);
		background-color: $white;
		border-radius: 100%;
		text-align: center;
		transform: translateX(-50%);
		border: 1px solid $silver2;
		vertical-align: top;
		img {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}

	.block-adress {
		max-width: rem(270);
		display: inline-block;
		h3 {
			font-size: rem(14);
			font-family: $font2;
			font-weight: bold;
		}
		.adress-info {
			font-size: rem(12);
			font-family: $font1;
			color: $maroon2;
			text-transform: uppercase;
			letter-spacing: 3px;
			margin-bottom: rem(10);
			line-height: 1.5;
		}
		.tel, .fax, .name, .email {
			text-transform: uppercase;
			letter-spacing: 3px;
			line-height: 1.5;
		}
		.tel {
			font-size: rem(14);
			font-family: $font2;
			font-weight: 600;
			color: $pistache;
		}
		.fax, .name, .email {
			font-family: $font1;
			color: $maroon2;
			font-size: rem(12);
		}
		.tel,.fax{
			pointer-events: none;
			@include bp-lt($tablet) {
				pointer-events:	auto;
			}
		}
		.infos {
			font-size: rem(12);
			margin-top: rem(10);
			line-height: 1.5;
		}
		.desc {
			font-size: rem(14);
			font-family: $font1;
			margin-bottom: rem(26);
			line-height: 1.5;
		}
	}

	.map {
		margin-top: rem(35);
		margin-bottom: rem(40);
		position: relative;
		.marker {
			position: absolute;
			display: block;
			width: rem(20);
			height: rem(20);
			background-color: $olive2;
			top: 50%;
			left: 50%;
			border-radius: 50%;
			&:after {
				content: '';
				width: 0;
				height: 0;
				position: absolute;
				border-left: 10px solid transparent;
				border-right: 10px solid transparent;
				border-top: 10px solid $olive2;
				bottom: -5px;
			}
		}
	}

	.adress:last-child {
		margin-top: rem(85);
		.block-adress {
			position: relative;
			&:before {
				content: '';
				position: absolute;
				top: -40px;
				display: block;
				width: 32px;
				height: 2px;
				background-color: $pistache;
			}
		}
	}

	@include bp-gt($desktopXL) {
		.contacter {
			max-width: rem(740);
			.form-wrapper {

				left: rem(120);
			}
		}
	}

	@include bp-bt($tabletAL, $desktopXL) {
		.contacter .form-wrapper { left: rem(40); }
	}

	@include bp-bt($medium, $tabletAL) {
		.contacter .form-wrapper { left: rem(20); }
	}

	@include bp-lt($tabletAL) {
		.contacter {
			&:before {
				left: 44px;
			}
			.title-left {
				margin-left: rem(44);
			}
		}
	}

	@include bp-lt($medium) {
		.contacter, .joindre {
			height: auto!important;
			padding-bottom: rem(20);
		}
		.joindre {
			padding-left: rem(10);
			padding-right: rem(10);
		}
		.joindre .title-left, .title-left {
			margin-left: rem(44);
		}
		.icon {
			transform: translateX(0);
		}
		.block-adress {
			margin-left: rem(20);
		}
	}

	@include bp-lt($tabletA) {
		.contacter {
			&:before {
				display: none;
			}
		}
	}

	@include bp-lt($mobileLO) {
		.checkboxes {
			columns: 1;
		}
		.joindre .title-left span, .title-left span{
			padding-left: rem(30);
		}
	}

	@include bp-lt($xsmall) {
		.icon {
			margin: 0 auto;
			display: block;
			margin-bottom: rem(15);
		}
		.adress:last-child {
			margin-top: rem(40);
			.block-adress:before {
				top: -70px;
			}
		}
	}
}
#map-lokoa {
	min-height: rem(698);
	@include bp-lt($medium) {
		min-height: rem(498);
	}
	@include bp-lt($small) {
		min-height: rem(298);
	}
}
