.page-actualites .page-title  h1 {
	padding-bottom: rem(135);
}

.node-type-article {
	.page-title {
		position: relative;
		
	}
	h1 {
		max-width: rem(900);
		margin: 0 auto;
		@include bp-lt($medium) {
			max-width: 90%;
		}
		@include bp-lt($tabletA) {
			max-width: 75%;
		}
		@include bp-lt($small) {
			max-width: 75%;
		}
	}
}

.page-news-detail {
	padding: rem(50) 0 rem(65);
	overflow: hidden;
	@include bp-lt($small) {
		padding: rem(20) 0 rem(10);
	}

	
	h1 {
		padding-bottom: 0;
	}
	p {
		padding-top: rem(30);
	}
	img {
		border: $border;
	}
	a{
		color: $pistache;
		text-decoration: underline;
		&:hover{
			color: $maroon;
		}
		
	}
	.thumb {
		position: relative;
		float: left;
		margin-right: rem(30);
		@include bp-lt($tabletA) {
			max-width: rem(500);
			float: none;
			margin: 0 auto;
		}
		.date {
			position: absolute;
			top: 1px;
			right: 1px;
			display: block;
			background-color: $maroon;
			color: $white;
			font-family: $font3;
			font-size: rem(17);
			padding: rem(15) rem(26);
		}
	}
    .float-left {
        float: left;
    }

    .float-right {
        float: right;
    }
}


@include bp-lt($small) {
	iframe {
		height: 250px !important;
	}
}



.partage-actualite {
	max-width: rem(1060);
	margin: 0 auto;
	position: relative;
	outline: none;
}

.partage {
	width: rem(40);
	height: rem(40);
	background-color: $pistache2;
	display: block;
	border-radius: 50%;
	position: absolute;
	right: 0;
	top: 50%;
	transform: translate(-50%, -100%);
	cursor: pointer;
	img {display: none; }
	&:before {
		content: "\e902";
		display: inline-block;
		color: $white;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		@extend %iconfont;
		font-size: rem(20);
	}
	&:after {
		content: "Partager";
		position: absolute;
		top: 12px;
		right: 50px;
	}
	@include bp-lt($small) {
		&:after {
			top: auto;
			bottom: -16px;
			right: -15px;
		}
	}
	@include bp-lt($xsmall) {
		width: rem(30);
		height: rem(30);
		&:before {
			font-size: rem(14);
		}
	}
	
}