// ========================================= //
// inputs
// ========================================= //
.form-item {
	margin-top: 0;
}
.webform-component-textfield, .webform-component-email, .webform-component-select, .webform-component-textarea {
	width: 50%;
	padding-left: rem(10);
	padding-right: rem(10);
	float: left;
	position : relative;
	margin-bottom: rem(27);
	.form-text, .form-textarea {
		border-radius: 10px;
		border-color: $silver12;
		@include box-shadow-none();
		margin-bottom: 0;
		height: rem(43);
		padding-left: rem(20);
		padding-right: rem(20);

	}

	label.error {
        top: auto;
        bottom: -22px;
        position: absolute;
    }

	label {
		padding-left: rem(10);
		padding-right: rem(10);
		margin-left: rem(10);
		margin-right: rem(10);
		background-color: $white;
		position: absolute;
		top: 0;
		transform: translateY(-50%);
		font-family: $font1;
		font-size: rem(12);
		text-transform: uppercase;
		letter-spacing: 2px;
		z-index: 1;
		span {
			color: $pistache;
			position: relative;
			top: -5px;
			left: -5px;
		}
		&.required {
			&:after {
				content: '*';
				color: $pistache;
				position: relative;
				top: -5px;
			}
		}
	}

	&.webform-component--address {
		width: 100%;
	}

	&.webform-component--phone {
		clear: both;
	}
	&.webform-component-textarea {
		width: 100%;
		.form-textarea {
			height: rem(175);
			resize: none;
		}
	}

	@include bp-lt($mobileLO) {
		width: 100%;
	}
}

.webform-component-radios, .webform-component-checkboxes {
	width: 100%;
	margin-bottom: rem(35);
	clear: both;
	> label {
		clear: both;
		font-weight: 300;
		text-transform: uppercase;
		font-size: rem(12);
		letter-spacing: 1px;
		margin: rem(20) rem(25);
		em {
			font-size: rem(10);
		}
	}

	.form-radios, .form-checkboxes {
		@include bp-gt($small) {
			columns: 3;
		}
		.form-type-checkbox {
            width: 100%;
			margin-bottom: rem(14);
			padding-left: rem(35);
			padding-right: rem(35);

            @include bp-lt($desktop) {
                padding-left: rem(15);
                padding-right: rem(15);
            }
		}

		label {
			display: inline-block;
			text-transform: none;
		}
	}
}

.form-type-radio, .form-type-checkbox {
	margin-bottom: rem(15);
	label {
		font-family: $font1;
		font-size: rem(14);
		color: $maroon2;
		font-weight: 300;
		width: 82%;
		vertical-align: middle;
		min-height: 1rem;
		padding: 0;
		margin: 0;
	}
}

.selector {
	width: 100%!important;
	span {
		&:before {
			content: "\e900";
			@extend %iconfont;
			font-size: rem(10);
			color: $pistache;
			font-weight: bold;
			position: absolute;
	        top: 50%;
	        right: 0;
	        transform: translateY(-50%);
	        border-left: 1px solid $silver13;
	        height: 100%;
	        width: rem(48);
	        text-align: center;
	        line-height: 43px;
		}
	}
}
