// ========================================= //
// emRemConvert
// ========================================= //

$browser-context: 16; // Default

// Convert px to em
@function em($pixels, $context: $browser-context) {
    @return #{$pixels/$context}em
}

// Convert px to rem
@function rem($pixels, $context: $browser-context) {
    @return #{$pixels/$context}rem
}


// rem convert 

$base-font-size: 16px;

@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}


@function rem($value) {
	@return ($value / strip-unit($base-font-size)) * 1rem;
}