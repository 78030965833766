// ========================================= //
// footer
// ========================================= //
footer {
	.biloba {
		text-align: center;
		background-color: $pistache2;
		padding-top: rem(30);
		padding-bottom: rem(25);
		@include bp-lt($small) {
			padding-bottom: 0;
		}
		img {
			margin-bottom: rem(25);
		}
		.item {
			width: 19%;
			display: inline-block;
			text-align: center;
			margin-bottom: rem(20);
			@include bp-lt($medium) {
				width: 30%;
			}
			@include bp-lt($mobileDefault) {
				width: 45%;
			}
			span {
				display: inline-block;
				width: rem(85);
				height: rem(85);
				border-radius: 50%;
				border: 1px solid rgba(255, 255, 255, 0.3);
				background-color: $pistache3;
				text-align: center;
				position: relative;
				img {
					color: $white;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					margin-bottom: 0;
				}
			}
			h2 {
				font-family: $font3;
				font-size: rem(13);
				font-weight: lighter;
				text-transform: uppercase;
				line-height: 1.3;
				color: $white;
				margin-top: rem(25);
				letter-spacing: 1px;
				strong {
					display: block;
					font-weight: bold;
					font-size: rem(19);
					@include bp-lt($xsmall) {
						font-size: 0.9rem;
					}
				}
			}
		}
	}
	.footer-top {
		box-shadow: 0 -6px 16px rgba(0, 0, 0, 0.2);
		border-bottom: $border;
		h2 {
			font-family: $font3;
			font-weight: bold;
			font-size: rem(18);
			text-transform: uppercase;
			margin-bottom: rem(10);
			@include bp-lt($medium) {
				font-size: rem(15);
			}
		}
		.logo {
			padding-top: rem(30);
			padding-bottom: rem(30);
			min-height: rem(145);
			border-right: $border;
			width: 36%;
			float: left;
			padding-left: rem(10);
			padding-right: rem(10);
			text-align: center;
			span {
				vertical-align: bottom;
				font-family: $font2;
				color: $pistache;
				font-size: rem(21);
				margin-left: rem(23);
				@include bp-lt($small) {
					margin-left: 0;
				}
			}
			@include bp-bt($tabletA, $tabletAL) {
				text-align: center;
				img {
					max-width: rem(200);
				}
				span {
					font-size: rem(14);
					margin-left: rem(8);
				}
			}
			@include bp-bt($xxsmall, $tabletA) {
				width: 25%;
				span {
					font-size: rem(14);
				}
			}
			@include bp-lt($xxsmall) {
				width: 50%;
				span {
					font-size: rem(14);
					display: block;
					margin-top: rem(8);
					margin-left: 0;
				}
			}
			@include bp-lt($xxsmall) {
				img {
					max-width: rem(200);
				}
				span {
					font-size: rem(18);
				}
			}
			@include bp-lt($tabletS) {
				width: 100%;
				border-right: none;
				text-align: center;
			}
		}
	}
	.newsletter {
		border-right: $border;
		text-align: center;
		width: 32%;
		float: left;
		min-height: rem(145);
		padding: rem(38) rem(10) 0;
		@include bp-lt($tabletA) {
			width: 40%;
		}
		@include bp-lt($xxsmall) {
			width: 50%;
		}
		@include bp-lt($tabletS) {
			width: 100%;
			min-height: auto;
			padding-top: 0;
		}
		form {
			position: relative;
			max-width: rem(357);
			display: block;
			margin: 0 auto;
			label.error {
				font-size: rem(12);
				text-align: left;
				margin-top: rem(5);
			}
			
			.form-text {
				border-radius: 8px;
				height: rem(45);
				line-height: 2;
				padding-left: rem(17);
				padding-right: rem(50);
				box-shadow: none;
				color: $primary-color;
				font-style: italic;
				font-size: rem(14);
				box-shadow: none;
				font-family: $font3;
				@include placeholder-color(transparentize($primary-color, 0.5));
				@media screen and (-webkit-min-device-pixel-ratio: 0) {
					&:focus {
						font-size: rem(14);
					}
				}
			}
			.form-submit {
				background: none;
				border: none;
				position: absolute;
				color: $pistache2;
				font-family: $font3;
				text-transform: uppercase;
				font-weight: bold;
				top: 12px;
				right: 20px;
				padding: 0;
				margin: 0;
				letter-spacing: 1px;
			}
		}
	}
	.social-network {
		text-align: center;
		width: 32%;
		float: left;
		min-height: rem(145);
		padding: rem(38) rem(10) rem(20);
		a {
			width: rem(40);
			height: rem(40);
			background-color: $maroon3;
			border-radius: 50%;
			display: inline-block;
			margin-right: rem(50);
			text-align: center;
			position: relative;
			transition: 0.3s background-color;
			@include bp-bt($xxsmall, $medium) {
				margin-right: rem(20);
			}
			&:last-child {
				margin-right: 0;
			}
			&:before {
				display: inline-block;
				color: $white;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				@extend %iconfont;
				font-size: rem(20);
			}
			&.facebook {
				&:before {
					content: "\e903";
				}
			}
			&.viadeo {
				&:before {
					content: "\e904";
				}
			}
			&.twitter {
				&:before {
					content: "\ea96";
				}
			}
			&.linkedin {
				&:before {
					content: "\eaca";
				}
			}
			&:hover {
				background-color: $pistache2;
			}
		}
		@include bp-bt($xxsmall, $tabletA) {
			width: 35%;
			a {
				margin-right: rem(20);
			}
		}
		@include bp-lt($xxsmall) {
			width: 100%;
			border-top: $border;
			padding-top: rem(20);
			min-height: auto;
		}
		@include bp-lt($tabletS) {
			width: 100%;
			border-top: none;
			a {
				margin-right: rem(30);
			}
		}
	}
	.footer-bottom {
		padding: rem(17) rem(10);
		@include bp-lt($tabletA) {
			padding-bottom: rem(40);
		}
		ul {
			text-align: center;
			li {
				display: inline-block;
				&:after {
					content: '';
					display: inline-block;
					width: 4px;
					height: 4px;
					border-radius: 50%;
					background-color: $pistache;
					margin: 0 rem(10);
					position: relative;
					bottom: 2px;
					@include bp-lt($xsmall) {
						margin: 0 rem(5);
					}
				}
				&:last-child:after {
					width: 0;
					margin: 0;
				}
				a {
					color: $primary-color;
					font-size: rem(15);
					font-family: $font1;
					transition: color 0.3s;
					@include bp-lt($xsmall) {
						font-size: rem(12);
					}
					&.dbm {
						font-size: 0;
						display: inline-block;
						width: rem(33);
						height: rem(10);
						background: url('#{$img}logo-dbm.png');
						vertical-align: middle;
					}
					&:hover {
						color: $pistache;
					}
				}
			}
		}
	}
}

.zopim {
	bottom: 0!important;
}
