.page-reference-testimonial {
	p {
		position: relative;
	}
	main {
		position: relative;
		&:before {
			content: '';
			background-color: $silver2;
			width: 1px;
			height: 100%;
			position: absolute;
			left: 11%;
			@include bp-gt($desktopXL) {
				left: 17%;
			}
			@include bp-bt($medium, $desktopXL) {
				left: 8%;
			}
			@include bp-lt($medium) {
				display: none;
			}

		}
	}
	.temoingage h2, h1 {
		text-align: left;
		margin-left: rem(60);
		padding-left: rem(10);
		padding-right: rem(10);
		position: relative;
		font: {
			size: rem(52);
			weight: bold;
		}
		span {
			font-size: rem(20);
			margin-top: rem(20);
			text-transform: uppercase;
			font-weight: normal;
			display: block;
			padding-left: rem(113);
			letter-spacing: 2px;
		}
		@include bp-lt($small) {
			font-size: rem(24);
			margin-left: rem(20);
			span {
				font-size: rem(14);
				padding-left: rem(60);
			}
		}
	}

	.temoingage h2 {
		@include bp-lt($small) {
			margin-bottom: 0;
		}
	}

	.wrapper {
		max-width: rem(1070);
		margin: 0 auto;
		padding-left: rem(10);
		padding-right: rem(10);
	}

	.block-ref {
		text-align: center;
		.ref-item {
			margin-top: rem(50);
			display: inline-block;
			margin-left: rem(35);
			margin-right: rem(35);
			border: $border;
			position: relative;
			cursor: pointer;
			@include bp-lt($medium) {
				margin-left: rem(28);
				margin-right: rem(28);
			}
			@include bp-lt($tablet) {
				margin-left: rem(10);
				margin-right: rem(10);
				margin-top: rem(20);
			}
			@include bp-lt($xxsmall) {
				margin-left: rem(30);
				margin-right: rem(30);
				margin-top: rem(30);
			}
			@include bp-lt($small) {
				margin-left: rem(10);
				margin-right: rem(10);
				margin-top: rem(20);
			}
			@include bp-lt($xsmall) {
				width: 40%;
				margin-left: rem(10);
				margin-right: rem(10);
				margin-top: rem(20);
			}
		}
	}
	.reference{
		@include bp-gt($medium) {
			&:nth-child(n4) {
				clear: left;
			}
		}
		&:last-child{
			float: left;
		}
		.ref-elem{
			margin-top: rem(50);
			margin-left: rem(35);
			margin-right: rem(35);
			border: $border;
			@include transition-mixin(all, 0.2s);
			display: block;
			text-align: center;
			@include bp-lt($medium) {
				margin: rem(20) rem(10) 0 rem(10);
			}
			&:hover{
				opacity: 0.8;
			}
			cursor: pointer;
		}

	}
	.temoingage {
		padding-top: rem(60);
		border-top: $border;
		border-bottom: $border;
		background-color: $silver;
		.wrapper {
			padding-top: rem(20);
		}


		p {
			margin-bottom: rem(50);
		}

		.block-temoingage {
			position: relative;
			margin-bottom: rem(30);
			img {
				position: absolute;
				top: 50%;
				left: 0;
				transform: translate(-50%, -50%);
				z-index: 1;
				border: $border;
			}
			blockquote {
				font-style: italic;
				font-family: $font1;
				color: $primary-color;
				line-height: 1.75;
				position: relative;
				background-color: $silver4;
				border: 1px solid $silver3;
				padding: rem(30) rem(50) rem(40) rem(125);
				p {
					margin-bottom: 0;
				}
				author {
					font-style: italic;
					font-family: $font3;
					display: block;
					font-weight: bold;
					margin-top: 1rem;
					font-style: normal;
				}
			}
		}

		.button {
			margin: 0 auto;
			width: rem(290);
			display: block;
			margin-top: rem(12);
		}

		@include bp-bt($small, $medium) {
			.block-temoingage {

			margin-left: rem(60);
			}
		}

		@include bp-lt($small) {
			padding-top: rem(20);
			.block-temoingage {
				margin-bottom: rem(60);
                background-color: $silver4;
				border: 1px solid $silver3;
                padding-top: rem(20);

				&:last-child {
					margin-bottom: rem(20);
				}
				img {
                    width: rem(100);
                    position: static;
                    top: auto;
                    left: auto;
                    transform: none;
                    margin: 0 auto;
                    display: block;
				}
				blockquote {
					padding: rem(20) rem(20);
                    border: none;
				}
			}
		}
	}
}
