#site-map {
	max-width: rem(1070);
	margin: 0 auto;
	float: none;
	padding: rem(50) rem(10) rem(20);
	h1, .site-map-front-page, .site-map-box-menu h2, .site-map-box-menu-menu-reseaux-sociaux {
		display: none;
	}
	ul.site-map-menu {
		> li {
			font-size: rem(20);
			margin-bottom: rem(15);
			font-weight: bold;
			font-family: $font2;
			a {
				color: $maroon;
				transition: 0.2s color;
				&:hover {
					color: $pistache;
				}
			}
			@include bp-lt($small) {
				width: 100%;
			}
			&:first-letter {
				text-transform: uppercase;
			}

			ul.site-map-menu {
				margin-top: rem(10);
				li {
					text-transform: capitalize;
					font-size: rem(14);
					font-family: $font1;
					margin-bottom: rem(5);
					width: inherit;
					a {
						transition: 0.3s color;
						font-weight: lighter;
						&:before {
							content: '>';
							display: inline-block;
							margin-right: rem(10);
						}
						&:hover {
							color: $olive;
						}
					}
				}
			}
		}
	}
}