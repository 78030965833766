// ========================================= //
// blocks
// ========================================= //
// block-actualite
.actualite-container {
	margin-top: rem(-130);
}

// page actualites
.block-actualite {
	border: $border;
	border-color: $silver2;
	margin-bottom: rem(50);
	background-color: $white;
	cursor: pointer;
	transition: 0.2s background-color;
	position: relative;
	@include bp-lt($small) {
		margin-bottom: rem(20);
	}
	.visuel {
		position: relative;
		float: left;
		margin-right: rem(48);

        @include bp-lt($large) {
            margin-right: 0;
        }

        @include bp-lt($desktop) {
            width: 50%;
            img {
                width: 100%;
            }
        }

        @include bp-lt($medium) {
            width: 100%;
		}
		@include bp-bt($small, $tabletA) {
			width: 100%;
			float: none;
			margin-right: 0;
			img {
				width: 100%;
			}
		}
		@include bp-lt($mobileXl) {
			width: 100%;
			float: none;
			margin-right: 0;
			img {
				width: 100%;
			}
		}
		&:after {
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background-color: transparentize($primary-color, 0.7);
			opacity: 0;
			transition: 0.3s opacity;
		}
		time {
			position: absolute;
			top: 0;
			right: 0;
			display: block;
			background-color: $maroon;
			color: $white;
			font-family: $font3;
			font-size: rem(17);
			padding: rem(15) rem(26);
		}
	}
	.desc {
		padding-top: rem(35);
		padding-right: rem(20);
		float: right;
		width: 53%;

        @include bp-lt($large) {
            padding-left: rem(10);
        }

        @include bp-lt($desktop) {
            width: 50%;
        }

		@include bp-lt($medium) {
			padding: rem(20) rem(10);
			width: 100%;
		}
		@include bp-bt($tabletA, $medium) {
			padding: rem(20) rem(10);
			width: 100%;
			height: rem(200);
		}
		@include bp-bt($small, $tabletA) {
			padding: rem(20) rem(10);
			height: rem(250);
		}
		@include bp-lt($tabletA) {
			padding-left: rem(10);
			padding-right: rem(10);
			padding-bottom: rem(20);
		}
		h2 {
			font-size: rem(26);
			margin-bottom: rem(20);
			font-family: $font2;
			font-weight: bold;

            @include bp-lt($desktop) {
                font-size: rem(20);
            }
		}
		p {
			font-size: rem(15);
			margin-bottom: rem(20);
			font-family: $font1;
			line-height: 1.5;
		}
		h2,
		p {
			color: $maroon;
			transition: transform 0.2s;
		}
		span {
			color: $pistache;
			font-family: $font3;
			font-weight: bold;
			font-size: rem(14);
			text-transform: uppercase;
			position: absolute;
			bottom: 30px;
            display: block;
			@include bp-lt($small) {
				position: static;
			}
			&:before {
				content: '>';
				color: $maroon;
				margin-right: rem(13);
				transition: 0.2s color;
			}
		}
	}
	&:hover {
		background-color: $maroon;
		color: $white;
		.visuel:after {
			opacity: 1;
		}
		h2,
		p,
		span:before {
			color: $white;
		}
		span {
			color: transparentize($pistache, 0.5);
		}
	}
}

// page home
.block-actualites {
	padding-top: rem(55);
	padding-bottom: rem(50);
	h2.title {
		text-align: center;
		margin-bottom: rem(50);
	}
	.button {
		display: block;
		margin: 0 auto;
		width: rem(300);
		clear: both;
	}
	@include bp-lt($small) {
		padding-top: rem(20);
		padding-bottom: rem(20);
		h2.title {
			margin-bottom: rem(20);
			font-size: rem(36);
		}
	}
}

// block temoignage
.block-temoignage {
	background-color: $silver;
	border-top: $border;
	padding-top: rem(70);
	padding-bottom: rem(60);
	.block-left {
		margin-right: rem(70);
		@include bp-lt($medium) {
			margin-right: rem(10);
		}
		h2 {
			font-family: $font2;
			font-weight: bold;
			font-size: rem(51);
			color: $maroon2;
			text-align: right;
			@include bp-lt($small) {
				text-align: center;
			}
			span {
				font-family: $font1;
				font-size: rem(20);
				text-transform: uppercase;
				display: block;
				letter-spacing: 3px;
				font-weight: lighter;
				margin-top: rem(20);
			}
		}
	}
	.block-right {
		max-width: rem(950);
		margin: 0 auto;
		.flexslider:hover {
			.flex-direction-nav {
				.flex-prev,
				.flex-next {
					opacity: 1;
				}
			}
		}
		.flex-direction-nav a {
			width: 18px;
			height: 18px;
			opacity: 1;
			&:before {
				font-size: 18px;
			}
			&.flex-prev {
				left: -30px;
			}
			&.flex-next {
				right: -30px;
			}
		}
		.block-slide {
			position: relative;
		}
		img {
			position: absolute;
			top: 50%;
			left: 40px;
			width: 140px;
			height: auto;
			transform: translateY(-50%);
			z-index: 1;
		}
		blockquote {
			font-style: italic;
			font-family: $font1;
			color: $primary-color;
			line-height: 1.75;
			position: relative;
			background-color: $silver4;
			border: 1px solid $silver3;
			padding: rem(30) rem(50) rem(40) rem(125);
			margin-left: rem(100);
			margin-right: rem(45);
			@include bp-lt($tabletA) {
				padding-left: rem(80);
				padding-right: rem(30);
			}
			author {
				font-style: italic;
				font-family: $font3;
				display: block;
				font-weight: bold;
				margin-top: 1rem;
				font-style: normal;
			}
		}
	}
	@include bp-lt($medium) {
		.block-left {
			text-align: center;
			h2 {
				text-align: inherit;
				span {
					padding-left: 0;
				}
			}
		}
	}
	@include bp-gt($medium) {
		padding-bottom: rem(10);
		.button {
			position: relative;
			top: -60px;
		}
	}
	@include bp-lt($tabletAL) {
		.flex-direction-nav {
			.flex-prev {
				left: 0;
			}
			.flex-next {
				right: 0;
			}
		}
		.button {
			margin-top: rem(20);
		}
	}
	@include bp-lt($tabletAL) {
		.flex-direction-nav {
			display: none;
		}
	}
	@include bp-lt($xxsmall) {
		.flex-direction-nav {
			display: none;
		}
	}
	@include bp-lt($small) {
		padding-top: rem(20);
		padding-bottom: rem(20);
		.block-left {
			text-align: center;
			h2 {
				font-size: rem(36);
			}
		}
		.block-right {
			.flex-direction-nav {
				display: none;
			}
			blockquote {
				padding: rem(60) rem(20) rem(20);
				margin-top: rem(70);
				margin-left: auto;
				margin-right: auto;
			}
			img {
				top: 0;
				left: 50%;
				transform: translate(-50%, -50%);
				@include bp-lt($small) {
					width: rem(100);
				}
			}
		}
	}
}

.solution {
	padding: rem(50) rem(10);
	@include bp-lt($small) {
		overflow: hidden;
	}
	.block-left {
		@include bp-gt($medium) {
			img {
				float: right;
				margin-top: -120px;
			}
		}
		h3 {
			font-size: rem(28);
			font-weight: 600;
			text-transform: none;
			letter-spacing: 0;
			margin-bottom: rem(30);
			@include bp-lt($xsmall) {
				text-align: center;
				margin-top: rem(10);
				margin-bottom: rem(10);
			}
		}
		p {
			text-align: justify;
			margin-bottom: rem(60);
			@include bp-lt($small) {
				margin-bottom: rem(10);
			}
		}
		.image-container {
			float: right;
			position: relative;
			.mascotte {
				width: 23%;
				position: absolute;
				background-repeat: no-repeat;
				bottom: 0;
				background-size: contain;
				left: 0;
			}
			@include bp-lt($medium) {
				float: none;
			}
		}
	}
}

// block outils pour tous page home
.block-outils {
	.row {
		max-width: 100%;
	}
	background-color: $silver;
	border-top: $border;
	border-bottom: $border;
	padding: rem(50) rem(10) rem(25);
	@include bp-gt($medium) {
		.title-left {
			margin: rem(20) 0 0 rem(75);
			span {
				padding-left: rem(160);

                @include bp-lt($desktop) {
                    padding-left: rem(100);
                }
			}
		}
	}
	.outil-item {
		max-width: rem(210);
		width: 100%;
		height: rem(165);
		margin: 0 auto rem(20);
		border-radius: rem(10);
		text-align: center;
		display: table;
		position: relative;
		transition: 0.2s all;
		border-bottom: 2px solid transparent;
		a {
			display: table-cell;
			vertical-align: middle;
			padding: 15px;

			img {
				margin-bottom: rem(35);
			}

			h3 {
				font-size: rem(15);
				letter-spacing: 0;
				margin-bottom: 0;
				width: 100%;
			}

			p {
				font-size: rem(14);
				line-height: rem(20);
				display: block;
				margin-top: 10px;
			}
		}
		.show-hover {
			display: none;
		}
		@include bp-gt($mobileDefault) {
			&:hover {
				background-color: $pistache;
				border-color: $olive2;

				a h3,
				a p {
					color: $white;
				}
				.show-hover {
					display: inline-block;
				}
				.hide-hover {
					display: none;
				}
			}
		}
	}
}

//page home
.block-prise-simple {
	padding: rem(70) rem(10);
	h2.title-left {
		color: $maroon;
		font-size: rem(20);
		font-family: $font1;
		text-transform: uppercase;
		letter-spacing: 3px;
		margin-bottom: rem(48);
		font-weight: normal;
		span {
			color: $maroon2;
			font-size: rem(48);
			font-family: $font2;
			text-transform: none;
			font-weight: bold;
			padding-left: rem(40);
			letter-spacing: 0;
			font-weight: bold;
		}
		@include bp-lt($small) {
			padding-left: 0;
			margin-top: rem(4);
			font-size: 1rem;
			letter-spacing: 1px;
			padding-left: 0;
			text-align: center;
			span {
				text-align: center;
				font-size: rem(36);
			}
		}
	}
	@include bp-gt($medium) {
		.block-left {
			padding-right: rem(60);
		}
	}
	p {
		text-align: justify;
		margin-bottom: rem(30);
	}
	.block-right {
		position: relative;
		border: $border;
		.logo-biloba {
			background-color: $white;
			display: inline-block;
			padding: rem(8) rem(20);
			box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
			position: absolute;
			bottom: -12px;
			right: -18px;
			@include bp-lt($tabletAL) {
				right: -8px;
			}
			@include bp-lt($xsmall) {
				width: 50%;
			}
		}
	}
}

.block-facilite-changement {
	background-color: $maroon;
	.columns {
		padding: 0;
	}
	.visuel {
		background: url('#{$img}bg-block-changement.png') repeat-y;
		text-align: center;
		padding: rem(10);
		position: relative;
		border-right: 1px solid $maroon-light;
		display: table;
		width: 100%;
		@include bp-lt($small) {
			height: rem(250) !important;
			border-right: none;
			border-bottom: 1px solid $maroon-light;
		}
		div {
			// padding: rem(20);
			display: table-cell;
			vertical-align: middle;
		}
	}
	.desc {
		padding: rem(60) rem(50) rem(65) rem(95);
		@include bp-lt($medium) {
			padding: rem(30) rem(20);
		}
		@include bp-lt($small) {
			height: auto !important;
		}
		h2,
		p {
			color: $white;
		}
		h2 {
			margin-bottom: rem(40);
		}
		p {
			text-align: justify;
		}
	}
}

// page outils
.block-avantage {
	border-right: $border;
	.avantages {
		max-width: rem(670);
	}
	.avantage {
		margin-bottom: rem(25);
		min-height: rem(115);
		.visuel {
			float: left;
			border: 1px solid $silver11;
			width: rem(110);
			height: rem(110);
			border-radius: 100%;
			position: relative;
			text-align: center;
			margin-right: rem(35);
			img {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}
		.desc {
			padding-top: rem(15);
			h3 {
				font-family: $font3;
				letter-spacing: 1px;
				margin-bottom: rem(10);
			}
		}
	}
	@include bp-lt($medium) {
		border-right: none;
		border-bottom: $border;
		.avantages {
			margin: 0 auto;
		}
	}
	@include bp-lt($small) {
		.avantage {
			margin: 0 auto;
			text-align: center;
			max-width: rem(450);
			margin-bottom: rem(45);
			.visuel {
				float: none;
				margin: 0 auto;
				margin-bottom: rem(10);
			}
		}
	}
}

//page outils
.block-fonction {
	padding-left: rem(10);
	padding-right: rem(10);
	margin-bottom: rem(25);
	&-content {
		background-color: $silver;
		border: 1px solid $silver5;
		padding: rem(35) rem(15) rem(27) rem(35);
		h3 {
			&:after {
				content: '';
				display: block;
				margin-top: rem(20);
				width: rem(32);
				height: 2px;
				background-color: $pistache;
			}
		}
		li p {
			margin-bottom: rem(12);
			&:before {
				display: inline-block;
				margin-right: rem(10);
				content: '>';
				color: $olive2;
			}
		}
	}
	@include bp-lt($small) {
		width: 100%;
		padding-left: 0;
		padding-right: 0;
		margin-bottom: rem(20);
		&:nth-child(even),
		&:nth-child(odd) {
			float: none;
		}
		&-content {
			padding: rem(15);
		}
	}
}

//page outils & solutions
.block-options {
	.option-item {
		width: 19%;
		display: inline-block;
		text-align: center;
		vertical-align: top;
		padding-left: rem(10);
		padding-right: rem(10);
		margin-bottom: rem(20);
		.visuel {
			border: 1px solid $silver6;
			width: rem(110);
			height: rem(110);
			border-radius: 100%;
			position: relative;
			text-align: center;
			margin: 0 auto;
			margin-bottom: rem(20);
			img {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}
		h3 {
			font-size: rem(13);
			font-family: $font3;
			font-weight: bold;
			letter-spacing: 0;
			max-width: rem(155);
			line-height: 1.5;
			margin: 0 auto;
		}
	}
	@include bp-lt($small) {
		.option-item {
			width: 49%;
		}
	}
	@include bp-lt($xsmall) {
		.option-item {
			width: 100%;
		}
	}
}

//page solution
.block-metier {
	margin: 0 auto;
	margin-bottom: rem(60);
	text-align: center;
	.visuel-block {
		display: inline-block;
		width: 54%;
	}
	.visuel {
		position: relative;
		text-align: right;
		max-width: rem(400);
		float: right;
		img {
			border: $border;
		}
		@extend %screenshot-hover;
	}
	.desc {
		text-align: left;
		display: table;
		width: 45%;
		padding-left: rem(35);
		padding-right: rem(35);
		float: right;
		.desc-content {
			height: rem(250);
			min-height: rem(250);
			display: table-cell;
			vertical-align: middle;
		}
		h3 {
			position: relative;
			margin-bottom: rem(50);
			&:after {
				content: '';
				display: block;
				position: absolute;
				bottom: -30px;
				width: rem(32);
				height: 2px;
				background-color: $pistache;
			}
		}
		p {
			margin-bottom: rem(10);
		}
		p,
		li {
			font-weight: 300;
			font-style: italic;
		}
	}
	@include bp-lt($medium) {
		.desc {
			padding-left: rem(10);
			padding-right: rem(10);
		}
	}
	@include bp-gt($mobileDefault) {
		&:nth-child(odd) {
			.visuel-block {
				float: right;
				.visuel {
					float: left;
				}
			}
			.visuel {
				text-align: left;
			}
			.desc {
				text-align: right;
				float: left;
				h3:after {
					right: 0;
				}
			}
		}
	}
	@include bp-lt($small) {
		.visuel-block {
			width: 100%;
			margin-bottom: rem(20);
			.visuel {
				float: none;
			}
		}
		.desc {
			width: 100%;
			.desc-content {
				min-height: auto;
				height: auto;
			}
		}
	}
}

//title block home en mobile + tablette
@include bp-lt($medium) {
	.solution,
	.block-outils,
	.block-facilite-changement,
	.block-prise-simple {
		h2.title-left {
			text-align: center;
			span {
				padding-left: 0;
			}
		}
	}
}

// bandeau vide
.headband {
	background-color: $white;
	height: rem(50);
}

.outils {
	.headband {
		background-color: $silver;
		border-top: 1px solid $silver5;
		border-bottom: 1px solid $silver5;
	}
}
