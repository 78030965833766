// ========================================= //
// colors
// ========================================= //

$white                  : #ffffff !default;
$black                  : #000000 !default;
$error                  : #da4f4f !default;
$grey                   : #9d9da6 !default;
$red                    : #ba2327 !default;
$orange                 : #ffa279 !default;

$selectionColor         : $white !default;
$selectionBackground    : $grey !default;

$mainBranding           : $black !default;
$subBranding            : $grey !default;

$mainFont               : $subBranding !default;

$primary-color: #21150d;

$grey1:#555555;


$maroon : #58321c;
$maroon2 : #2f1f1b;
$maroon3 : #4d2c19;
$maroon-light : #896653;


$pistache : #becd00;
$pistache2 : #b3c105;
$pistache3 : #acb905;
$pistache4 : #cbd576;

$olive : #8f9800;
$olive2 : #9eaa04;




$silver : #f5f5f5;
$silver2 : #e6e6e6;
$silver3 : #efefef;
$silver4 : #fefffd;
$silver5 : #d4d4d4;
$silver6 : #d8d3d2;
$silver7 : #ececec;
$silver8 : #dfdfdf;
$silver9 : #f0f0f0;
$silver10 : #e1e1e1;
$silver11 : #ededed;
$silver12 : #d3d0cf;
$silver13 : #c1c1c1;








$border: 1px solid $silver2;


$breadcrumb-color: $maroon;

$breadcrumb-color-link: $pistache;
