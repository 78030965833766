// ========================================= //
// breadcrumb
// ========================================= //

.c-breadcrumb{
	border-bottom: $border;
	background-color: $white;
	position: relative;
	@include bp-lt($small) {
		padding-left: rem(5);
	}


	.c-breadcrumb__list{
		font-family: $font1;
		font-size: 0;
		padding: rem(15) 0 rem(13);
		li{
			display: inline-block;
			color: $breadcrumb-color;
			font-weight: normal;
			font-size: rem(14);
			line-height: 1.2;
			&:first-letter {
				text-transform: capitalize;
			}

            @include bp-lt($small) {
                display: none;

                &:first-of-type { display: inline-block; }
                &:last-of-type { display: inline-block; }
            }
            
			&:not(:last-child) {
				span,
				a{
					color: $breadcrumb-color-link;
					&:after{
						content: '>';
						display: inline-block;
						margin: 0 rem(7);

					}
				}
			}
		}

	}
}
