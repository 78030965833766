.item-list {
	padding: rem(8) 0 rem(51);
	@include bp-lt($small) { padding-bottom: rem(8); }
	ul.pager {
		li {
			display: inline-block;
			padding: rem(8) rem(11);
			@include bp-lt($small) { margin: 0; }
			
			color: $pistache;
			a {
				font-family: $font2;
				font-weight: bold;
				color : $maroon;
				&:hover {
					color: $pistache;
				}
			}
			&.pager-next, &.pager-last, &.pager-first, &.pager-previous {
				a { font-size: 0; &:before { font-size : 1rem; } }
			}
			&.pager-next {
				a { &:before { content: '>'; } }
			}
			&.pager-last {
				a { &:before { content: '>>'; } }
			}
			&.pager-first {
				a { &:before { content: '<<'; } }
			}
			&.pager-previous {
				a { &:before { content: '<'; } }
			}
		}
	}
}