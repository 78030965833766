.bandeau {
	max-width: 100%;
	background-color: $maroon;
	.visuel,
	.desc {
		padding: 0;
	}
	.visuel {
		position: relative;
		img {
			width: 100%;
			&.mascotte {
				width: auto;
				position: absolute;
				bottom: -30px;
				right: -20px;
				z-index: 1;
			}
		}
	}
	.desc {
		display: table;
		background-color: $maroon;
		padding-left: rem(70);
		position: relative;
		.icon {
			display: block;
			width: rem(70);
			height: rem(70);
			background-color: $pistache2;
			position: relative;
			border-radius: 100%;
			text-align: center;
			position: absolute;
			left: 0;
			top: 35%;
			transform: translateX(-50%);
			img {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}
		h1,
		p {
			color: $white
		}
		h1 {
			font-size: rem(46);
			&:after {
				display: block;
				content: '';
				height: 2px;
				width: rem(32);
				background-color: $pistache;
				margin-top: rem(30);
				margin-bottom: rem(30);
				@include bp-lt($medium) {
					margin: rem(30) auto;
				}
			}
			@include bp-lt($medium) {
				text-align: center;
			}
		}
		p {
			max-width: rem(340);
			@include bp-lt($medium) {
				margin: 0 auto;
				text-align: center;
				max-width: 100%;
			}
		}
		div {
			display: table-cell;
			vertical-align: middle;
		}
	}
	@include bp-lt($medium) {
		.visuel,
		.desc {
			height: auto !important
		}
		.visuel {
			img.mascotte {
				right: 10px;
			}
		}
		.desc {
			padding: rem(40) rem(15) rem(20);
			.icon {
				top: 0;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}
	}
	@include bp-lt($small) {
		.desc {
			h1 {
				font-size: rem(38);
			}
		}
		.visuel {
			img.mascotte {
				width: 20%;
			}
		}
	}
}

.intro {
	max-width: rem(1090);
	margin: rem(45) auto;
	text-align: center;
	padding-left: rem(10);
	padding-right: rem(10);
	@include bp-lt($small) {
		margin-bottom: rem(20);
	}
}

.bandeau-contact {
	max-width: 100%;
	background-color: $maroon;
	.title-bandeau {
		border-right: 1px solid $maroon-light;
		box-shadow: -160px 0 150px rgba(255, 255, 255, 0.1) inset;
	}
	h2.title-left {
		color: $white;
		font-size: rem(38);
		text-align: center;
		margin-top: rem(45);
		margin-bottom: rem(40);
	}
	.btn-row {
		display: table;
		.btn-bandeau {
			display: table-cell;
			vertical-align: middle;
			.button {
				margin-left: rem(70);
			}
		}
	}
	@include bp-lt($tablet) {
		.btn-row .btn-bandeau {
			text-align: center;
			.button {
				margin-left: 0;
			}
		}
	}
}

.block-prise-simple {
	.block-right {
		.slides li .img-screenshot {
			position: relative;
			@extend %screenshot-hover;
		}
		img {
			border: $border;
		}
	}
}

.outils {
	.block-avantage-biloba {
		margin-bottom: rem(30);
		h2.title-left {
			margin-bottom: rem(40);
			span {
				padding-left: rem(120);
			}
		}
	}
	.plus-biloba {
		padding-left: rem(55);
		h2.title-left span {
			padding-left: rem(95);
		}
		.item {
			margin-bottom: rem(25);
			p {
				font-size: rem(18);
				&:before {
					@extend %iconfont;
					content: "\e905";
					display: inline-block;
					background-color: $pistache;
					padding: rem(10);
					text-align: center;
					border-radius: 50%;
					margin-right: rem(35);
					color: $white;
					font-size: rem(14);
				}
			}
		}
	}
	.fonction-disponible {
		padding: rem(50) rem(10);
		max-width: rem(1100);
		h2.title-center {
			margin-bottom: rem(40);
		}
		.button.secondary {
			background-color: $maroon;
			margin-top: 0;
			h2 {
				color: $white;
				text-align: center;
				text-transform: none;
				font-size: 2rem;
				margin-bottom: 0;
				span {
					padding-left: 0;
					margin-top: rem(10);
					font-size: 1rem;
					letter-spacing: 3px;
				}
			}
			&:hover {
				background-color: $maroon3;
			}
		}
		.button {
			margin-top: rem(25);
		}
	}
	.options {
		padding-top: rem(40);
		padding-bottom: rem(30);
		border-top: 1px solid $silver5;
		border-bottom: 1px solid $silver5;
		background-color: $silver;
		text-align: center;
		.row {
			max-width: rem(1070);
		}
		.title-center {
			margin-bottom: rem(40);
		}
	}
	.screenshot {
		max-width: rem(1070);
		margin: rem(50) auto rem(65);
		.slides li .img-screenshot {
			position: relative;
			@extend %screenshot-hover;
		}
		img {
			border: $border;
		}
		.flexslider {
			.flex-direction-nav .flex-prev {
				left: -80px;
			}
			.flex-direction-nav .flex-next {
				right: -80px;
			}
		}
		.flex-direction-nav a {
			font-size: 0;
			opacity: 1;
			background-color: $white;
			border: $border;
			text-align: center;
			width: 55px;
			height: 55px;
			&:before {
				font-size: rem(18);
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}
	}
	@include bp-lt($medium) {
		.plus-biloba {
			padding-left: 0;
			margin-top: rem(40);
			.items {
				max-width: rem(670);
				margin: 0 auto;
			}
		}
		.fonction-disponible .button.secondary {
			padding: rem(18);
			h2 {
				font-size: rem(22);
				span {
					font-size: rem(14);
					letter-spacing: 2px;
				}
			}
		}
		.screenshot {
			.flexslider {
				.flex-direction-nav .flex-prev {
					left: 0;
				}
				.flex-direction-nav .flex-next {
					right: 0;
				}
			}
		}
	}
	@include bp-lt($tabletA) {
		.bandeau-contact {
			h2.title-left span {
				padding-left: rem(110);
			}
			.btn-row .btn-bandeau {
				text-align: center;
				.button {
					margin-left: 0;
				}
			}
		}
	}
	@include bp-lt($mobileDefault) {
		.bandeau-contact {
			h2.title-left span {
				padding-left: rem(20);
			}
		}
	}
	@include bp-lt($small) {
		.block-avantage-biloba h2.title-left span {
			padding-left: rem(40);
		}
		.plus-biloba {
			padding-left: rem(10);
			margin-top: rem(20);
			.item p:before {
				margin-right: rem(10);
			}
		}
		.bandeau-contact {
			.title-bandeau {
				border-right: 0;
				border-bottom: 1px solid $maroon-light;
			}
			.btn-row .btn-bandeau {
				text-align: center;
				.button {
					margin-left: 0;
				}
			}
		}
		.screenshot {
			margin: rem(20) auto;
		}
	}
	@include bp-lt($mobileLO) {
		.fonction-disponible {
			.button.secondary {
				padding: rem(20) rem(8);
				h2 {
					font-size: 1rem;
					span {
						font-size: rem(11);
					}
				}
			}
			.button {
				font-size: rem(14);
			}
		}
	}
}
