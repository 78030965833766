.solutions {
	.title-center {
		font-size: rem(38);
	}
	.icon {
		display: block;
		width: rem(70);
		height: rem(70);
		background-color: $pistache2;
		position: relative;
		border-radius: 100%;
		text-align: center;
		margin: 0 auto;
		margin-bottom: rem(35);
		border: $border;
		img {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
	.metier {
		max-width: rem(1090);
		margin: 0 auto;
		padding-left: rem(10);
		padding-right: rem(10);
		.title-center {
			margin-bottom: rem(30);
			span {
				margin-top: rem(15);
			}
		}
	}
	.metiers {
		position: relative;
		&:before {
			content: '';
			position: absolute;
			height: 100%;
			width: 1px;
			display: block;
			margin: 0 auto;
			left: 50%;
			top: 0;
			transform: translateX(-50%);
			background-color: $silver2;
		}
	}
	.bandeau-contact {
		h2.title-left {
			color: $white;
			font-family: $font4;
			font-size: rem(20);
			text-transform: uppercase;
			letter-spacing: 3px;
			font-weight: 300;
			span {
				font-size: rem(38);
				text-align: center;
				margin-top: rem(10);
				margin-bottom: rem(40);
				text-transform: none;
				font-weight: bold;
				letter-spacing: 1px;
				padding-left: rem(310);
			}
		}
	}
	.besoin {
		background-color: $silver;
		border-top: $border;
		border-bottom: $border;
		padding-top: rem(40);
		padding-bottom: rem(25);
		.row {
			max-width: rem(1090);
			margin: rem(10) auto;
		}
		.title-center {
			margin-bottom: rem(35);
		}
		.block-besoin {
			width: 50%;
			padding-left: rem(35);
			padding-right: rem(35);
			li {
				margin-bottom: rem(25);
				overflow: hidden;
				a {
					@include transition-mixin(all, 0.2s);
					&:after {
						@extend %iconfont;
						content: "\e905";
						display: inline-block;
						background-color: $pistache;
						padding: rem(10);
						text-align: center;
						border-radius: 50%;
						float: left;
						color: $white;
						font-size: rem(14);
						margin-right: rem(35);
					}
					&:hover {
						opacity: 0.5;
					}
				}
				p {
					display: inline-block;
					width: 80%;
					vertical-align: top;
				}
			}
			&:nth-child(even) {
				float: left;
				border-right: $border;
				li {
					text-align: right;
					a {
						@include bp-gt($small) {
							&:after {
								float: right;
								margin-left: rem(35);
								text-align: right;
								margin-right: 0;
							}
						}
					}
				}
			}
			&:nth-child(odd) {
				float: right;
				li:before {
					margin-right: rem(35);
				}
			}
		}
	}
	.options {
		padding-top: rem(40);
		padding-bottom: rem(30);
		text-align: center;
		.row {
			max-width: rem(1070);
		}
		.title-center {
			margin-bottom: rem(40);
		}
	}
	.option-item {
		width: 19%;
	}
	@include bp-lt($tabletAL) {
		.bandeau-contact h2.title-left span {
			padding-left: 0;
		}
	}
	@include bp-lt($tabletA) {
		.option-item {
			width: 30%;
		}
		.besoin {
			.block-besoin {
				padding-left: rem(10);
				padding-right: rem(10);
				&:nth-child(even) {
					li:before {
						margin-left: rem(10);
					}
				}
				&:nth-child(odd) {
					li:before {
						margin-right: rem(10);
					}
				}
			}
		}
	}
	@include bp-bt($small, $mobileDefault) {
		.metier {
			.title-center {
				text-align: left;
			}
		}
		.icon {
			margin: 0;
			margin-bottom: rem(30);
		}
		.metiers:before {
			left: 35px;
		}
	}
	@include bp-lt($small) {
		.option-item {
			width: 49%;
		}
		.metiers:before {
			display: none;
		}
		.besoin {
			.block-besoin {
				width: 100%;
				&:nth-child(even) {
					border-right: none;
					li {
						text-align: left;
						&:before {
							float: left;
							margin-left: 0;
							margin-right: rem(15);
							text-align: right;
						}
					}
				}
			}
		}
	}
	@include bp-lt($xsmall) {
		.option-item {
			width: 100%;
		}
		.bandeau-contact h2.title-left span {
			font-size: rem(28);
		}
	}
	@include bp-lt($mobileDefault) {
		.bandeau-contact h2.title-left span {
			font-size: rem(34);
		}
	}
}

.node-type-solution .mfp-content {
	background-color: $white;
	max-width: rem(1070);
	margin: 0 auto;
	.block-metier {
		float: none;
		.visuel:before {
			display: none;
		}
	}
}
