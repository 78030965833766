// ========================================= //
// header
// ========================================= //
.scrollTop {
    display: block;
    width: 50px;
    height: 50px;
    background-color: $pistache;
    border-bottom: 2px solid $pistache2;
    border-radius: 50%;
    text-align: center;
    line-height: rem(56);
    position: fixed;
    bottom: 60px;
    right: 30px;
    z-index: 1;
    cursor: pointer;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;

    &:before {
        content: "\e901";
        @extend %iconfont;
        font-size: rem(24);
        color: $white;
    }
    @include bp-lt($medium) {
        display: none!important;
    }

    &.is-visible {
        opacity: 1;
        visibility: visible;
        pointer-events: all;
    }
}

header {
    padding: rem(18) rem(10) rem(20);
    border-bottom: $border;
    border-color: $silver;
    background-color: $white;
    z-index: 11 !important;
    position: fixed;
    width: 100%;
    top: 0;
    &.fix-height {
        width: 100%;
        height: 100%;
        overflow-y: auto;
        position: fixed;
    }
    @include bp-lt($mobileDefault) {
        span.burger {
            z-index: 555;
            position: absolute;
            top: 35px;
            right: 20px;
            width: 25px;
            height: 4px;
            background-color: $primary-color;
            display: block;
            cursor: pointer;
            @include bp-lt($mobileS) {
                right: 10px;
            }
            &:after,
            &:before {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: $primary-color;
                transition: transform 0.5s;
            }
            &:after {
                top: 8px;
            }
            &:before {
                bottom: 8px;
            }
            &.open {
                background-color: transparent;
                &:after {
                    transform: rotate(45deg);
                    top: 0;
                }
                &:before {
                    transform: rotate(-45deg);
                    bottom: 0;
                }
            }
        }
    }
    .logo {
        width: 37%;
        float: left;
        img {
            transition: all .3s linear 0s;
        }
        h1 {
            text-align: left;
            @include bp-bt($small, $tabletA) {
                text-align: center;
            }
            span {
                text-transform: none;
                display: inherit;
                padding-left: 0;
                letter-spacing: 0;
                @include bp-lt($medium) {
                    display: block;
                    margin-left: 0;
                }
                @include bp-bt($tabletA, $tabletAL) {
                    display: block;
                }
            }
        }
        @include bp-lt($tabletAL) {
            width: 30%;
        }

        @include bp-lt($medium) {
            width: 20%;
        }
        @include bp-lt($tabletA) {
            width: 100%;
            text-align: center;
        }
        @include bp-lt($small) {
            margin-bottom: rem(10);
            padding-left: rem(10);
        }
        @include bp-lt($xsmall) {
            text-align: left;
        }
        span {
            vertical-align: bottom;
            font-family: $font2;
            color: $pistache;
            font-size: rem(21);
            margin-left: rem(23);
            transition: all .3s linear 0s;

            @include bp-lt($large) {
                font-size: rem(19);
            }

            @include bp-lt($desktopL) {
                margin-top: rem(8);
            }

            @include bp-bt($medium, $tabletAL) {
                display: block;
            }

            @include bp-lt($medium) { display: block; font-size: rem(15); }
            @include bp-lt($xsmall) {
                display: block;
            }
            @include bp-lt($mobileDefault) {
                margin-left: 0;
            }
        }
    }
    @include bp-lt($xxsmall) {
        padding: rem(10) 0;
        .logo {
            img {
                max-width: rem(150);
                margin-right: rem(5);
            }
            span {
                font-size: rem(14);
            }
        }
    }
    @include bp-lt($xsmall) {
        .logo {
            margin-bottom: 0;
        }
        span.burger {
            top: 50px
        }
    }
    &.sticky {
        .logo {
            a {
                img {
                    max-width: 150px;
                }
                span {
                    font-size: 18px;
                }
            }
        }
        .principal-nav {
            margin-top: 35px;
        }
    }
}
