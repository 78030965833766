%iconfont {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

%screenshot-hover {
  &:after {
      content: "\e905";
      @extend %iconfont;
      font-size: rem(28);
      color: $white;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      transition : 0.3s opacity;
    }
      
    &:before {
      content: '';
      top: 0;left: 0;right: 0;bottom: 0;
      background-color: transparentize($pistache, 0.3);
      position: absolute;
      opacity: 0;
      transition : 0.3s opacity;
    }
    &:hover:before, &:hover:after { opacity: 1; }
}


// [class^="icon-"], [class*=" icon-"] {
//   /* use !important to prevent issues with browser extensions that change fonts */
//   font-family: 'icomoon' !important;
//   speak: none;
//   font-style: normal;
//   font-weight: normal;
//   font-variant: normal;
//   text-transform: none;
//   line-height: 1;

//   /* Better Font Rendering =========== */
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
// }