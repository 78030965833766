// ========================================= //
// wysiwyg
// ========================================= //
 @import url('https://fonts.googleapis.com/css?family=Merriweather+Sans:300,400,700|Muli:300,400|Titillium+Web:300,400,600,700');


    @font-face {
      font-family: 'icomoon';
      src:  url('#{$fonts}icomoon.eot?ftbugk');
      src:  url('#{$fonts}icomoon.eot?ftbugk#iefix') format('embedded-opentype'),
        url('#{$fonts}icomoon.ttf?ftbugk') format('truetype'),
        url('#{$fonts}icomoon.woff?ftbugk') format('woff'),
        url('#{$fonts}icomoon.svg?ftbugk#icomoon') format('svg');
      font-weight: normal;
      font-style: normal;
    }

.CSS1Compat {
    max-width: rem(1070);
    margin: 0 auto;
    padding-bottom: 0;
    padding-top: 1em;
    
    body {
        font-family: $font1;
        color: $maroon2;
        
    }

    p {
        font-size: rem(15);
        font-family: $font1;
        line-height: 2;
        margin-bottom: rem(28);
    }

    h1, h2, h3, h4 {
        color: $maroon2;
        font-family: $font2;
    }

    h2, h3, h4 {
        margin-bottom: rem(20);
    }

    h3, h4 {
        letter-spacing: 2px;
        text-transform: uppercase;
    }

    h2 {
        font-weight: 600;
        font-size: rem(26);
    }
    h3 {
        font-weight: bold;
        font-size: 1rem;
    }

    h4 {
        color: $pistache2;
        font-size: rem(14);
    }

    h1, h2 {
        font: {
            size: rem(52);
            weight: bold;
        }
        span {
            font-size: rem(20);
            margin-top: rem(20);
            text-transform: uppercase;
            font-weight: normal;
            display: block;
            padding-left: rem(113);
            letter-spacing: 2px;
        }
        @include bp-lt($xsmall) {
            font-size: rem(24);
            span {
                font-size: rem(14);
                padding-left: rem(20);
            }
        }
    }

    .float-left {
        float: left;
    }

    .float-right {
        float: right;
    }

    

    strong { font-weight: bold; }
    em, i { font-style: italic; }
    hr {
        margin: rem(35) 0;
    }
    table {
        width: 100%;
        border: $border;
        border-color:  $silver5;
        margin-top: rem(35);
        margin-bottom: rem(35);
        thead , tbody {
            tr {
                th, td {
                    padding: rem(18) rem(22) rem(14);
                    border: 1px solid $silver6;
                    background-color: $silver;
                }
            }
        }
        thead tr td, tbody tr th {
            background-color: $silver7;
        }
        thead td:first-child {
            background-color: $silver8;
        }
        
        tr { width: 25%; }

        tbody th,  thead td {
            color: $maroon2;
            font-size: rem(15);
            font-family: $font2;
            text-transform: uppercase;
            letter-spacing: 2px;
            font-weight: bold;
        }

        @include bp-lt($small) {
            margin-top: rem(20);
        }
    }

    h2, .h2 {}

    h3, .h3 {}

    h4, .h4 {}
    img {
        &.alignright,
        &.align-right {
            float: right;
        }

        &.alignleft,
        &.align-left {
            float: left;
        }

        &.aligncenter,
        &.align-center {
            display: block;
            clear: both;
            margin-left: auto;
            margin-right: auto;
        }
    }

    ul {
        li {
            margin-bottom: rem(12);
            color: $maroon2;
            margin-left: rem(14);
            &:before {
                content: '';
                display: inline-block;
                width: 5px;
                height: 5px;
                border-radius: 50%;
                background-color: $pistache;
                margin-right: 1rem;
                position: relative;
                bottom: 3px;
            }
        }
    }
    blockquote {
        font-family: $font1;
        font-size: rem(15);
        font-style: italic;
        line-height: 1.75;
        text-align: center;
        color: $primary-color;
        margin: rem(80) rem(50) rem(50);
        padding: 0 rem(80);
        position: relative;
        @include bp-lt($medium) {
            padding: 0;
        }
        @include bp-lt($small) {
            margin: rem(20) 0;
        }

        &:before, &:after {
            position: absolute;
            color: $pistache4;
            @extend %iconfont;
            font-size: rem(50);
        }

        &:before {
            content: "\e977";
            top: 0;
            left: 0;
            transform: translateY(-50%);
        }

        &:after {
            content: "\e978";
            bottom: 0;
            right: 0;
            transform: translateY(50%);
        }
    }
}

// .c-table__wrapper {
//     width: 100%;
//     overflow-y: auto;
//     _overflow: auto;
//     margin: 0 0 $gutter;

//     &::-webkit-scrollbar
//     {
//         -webkit-appearance: none;
//         width: rem(14);
//         height: rem(14);
//     }

//     &::-webkit-scrollbar-thumb
//     {
//         border-radius: rem(8);
//         border: rem(3) solid $white;
//         background-color: rgba(0, 0, 0, .3);
//     }

//     table {
//         margin-bottom: 0;
//     }
// }